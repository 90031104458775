import React, { Suspense, useEffect, useState, useTransition } from "react";
import create from "zustand";
import { useControls } from "leva";
import { Demo, Footer, Glasses, Hair, HeadDemo, Navbar, YMallGlasses } from "../../../components";
import { Environment, Html, OrbitControls, PerspectiveCamera, TransformControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import Spinner from "../../../components/common/Spinner";
import { useLocation } from "react-router-dom";

const useStore = create((set) => ({ target: null, setTarget: (target) => set({ target }) }));

function Loader() {
    return (
        <Html center>
            <Spinner />
        </Html>
    );
}

const YMallPage = () => {
    const isHomepage = false;
    const [preset, setPreset] = useState("studio");
    const [inTransition, startTransition] = useTransition();
    const data = useLocation();
    useEffect(() => {
        if (data.state) {
            startTransition(() => setPreset(data.state));
        }
    }, []);

    const { modes, glasses, hairStyles, eyes, background } = useControls({
        modes: { value: "translate", options: ["translate", "rotate", "scale"] },
        glasses: {
            value: "none",
            options: [
                "YMallGlasses1",
                "YMallGlasses2",
                "YMallGlasses3",
                "YMallGlasses4",
                "YMallGlasses5",
                "YMallGlasses6",
                "YMallGlasses7",
            ],
        },
        hairStyles: {
            value: "none",
            options: [
                "menHairStyle1",
                "menHairStyle2",
                "menHairStyle3",
                "menHairStyle4",
                "menHairStyle5",
                "femaleHairStyle1",
                "femaleHairStyle2",
                "femaleHairStyle3",
                "femaleHairStyle4",
                "femaleHairStyle5",
            ],
        },
        eyes: {
            value: "brownEyes",
            options: ["brownEyes", "blueEyes", "darkBrownEyes", "greyEyes", "greenEyes", "hazelEyes"],
        },
        background: {
            value: preset,
            options: ["sunset", "forest", "city", "lobby", "apartment", "dawn", "studio", "park", "night", "warehouse"],
            onChange: (value) => startTransition(() => setPreset(value)),
        },
    });
    const { target, setTarget } = useStore();
    return (
        <div>
            <div className="gradient__bg">
                <Navbar HomepageOrNot={isHomepage} />
            </div>
            <div className="demoPage_demo-container" style={{ height: window.innerHeight }}>
                <Canvas camera={{ position: [0, 10, 50] }} onPointerMissed={() => setTarget(null)}>
                    <PerspectiveCamera position={[0.1, 0.1, -0.2]}>
                        <ambientLight args={[0x222222]} intensity={1} />
                        <directionalLight color="0xffffff" position={[1, 0.75, 1]} intensity={1} />
                        <directionalLight color="0xffffff" position={[-1, 0.75, -0.5]} intensity={1} />
                        <directionalLight color="0xffffff" position={[-0.5, 0.5, -0.5]} intensity={1} />
                        <directionalLight color="0x666666" position={[0, -0.5, 1]} intensity={1} />
                        <Suspense fallback={<Loader />}>
                            <HeadDemo name="Demo" modes={modes} useStore={useStore} eyeSelection={eyes} />
                            {glasses !== "none" ? (
                                <YMallGlasses
                                    name="Glasses"
                                    modes={modes}
                                    useStore={useStore}
                                    glassesSelection={glasses}
                                />
                            ) : null}
                            {hairStyles !== "none" ? (
                                <Hair name="Hair1" modes={modes} useStore={useStore} hairSelection={hairStyles} />
                            ) : null}
                            <Environment preset={preset} background />j
                            {/* <Environment
                                files={
                                    "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/background/artist_workshop_4k.hdr"
                                }
                                background
                            /> */}
                            {target && <TransformControls object={target} mode={modes} />}
                        </Suspense>
                        <OrbitControls makeDefault />
                    </PerspectiveCamera>
                </Canvas>
            </div>
            <div className="gradient__bg demoPage_footer">
                <Footer />
            </div>
        </div>
    );
};

export default YMallPage;
