import React, { useEffect } from "react";

import "./values.scss";
const Values = (props) => {
    useEffect(() => {
        props.scrollAnimate("values-content-values");
        props.scrollAnimate("values-content-commitment");
    }, []);
    return (
        <div className="values">
            <div className="values-content-container">
                <div className="values-content-values">
                    <h1>Our Values</h1>
                    <div className="values-card">
                        <p>Seek to understand, then share what is found. Curiosity is the beating heart of us.</p>
                    </div>
                    <div className="values-card">
                        <p>
                            Innovate and adapt to change. Challenge yourself and the status quo to walk the path less
                            trodden and leave your mark.
                        </p>
                    </div>
                    <div className="values-card">
                        <p>Be design led and user centric. Delight our customers at every touchpoint.</p>
                    </div>
                    <div className="values-card">
                        <p>
                            Good things can be achieved alone, but great things can be achieved when we work together.
                        </p>
                    </div>
                </div>
                <div className="values-content-commitment">
                    <div className="values-content-commitment-card">
                        <h1>Join the team</h1>
                        <div>
                            <p>
                                Sharp, agile, innovative, quick-witted, Ipomoea is a multiverse of talent—a family. We
                                wouldn’t be where we are without our people, culture, partners, and customers.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Values;
