import React, { useState } from "react";
import "./footer.style.scss";
import logo from "../../assets/Logo.png";

const Footer = () => {
    const [submitted, setSubmitted] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const handleSubmit = () => {
        setSubmitted(true);
        setTimeout(() => {
            setSubmitted(false);
            setSubmitSuccess(true);
        }, 3000);
    };
    return (
        <div className="footer" id="contactus">
            <div className="footer-links_logo">
                <img src={logo} alt="logo" />
                <h1>Ipomoea</h1>
            </div>
            <div className="footer-content-container">
                <div className="footer-content-box">
                    <div className="footer-content-slogan">
                        Subscribe to our newsletter to stay up to date with the latest news
                    </div>

                    {submitSuccess ? null : (
                        <div className="footer-content-user-box">
                            <input type="text" name="" required="@" placeholder="Your Email" />
                            <div className="footer-content-button-wrapper" onClick={handleSubmit}>
                                <a className="footer-content-button">{submitted ? "Please Wait..." : "Subscribe"}</a>
                            </div>
                        </div>
                    )}
                    {submitSuccess ? (
                        <div className="footer-content-submission">
                            <p>Thank you! Your submission has been received!</p>
                        </div>
                    ) : null}

                    <div className="footer-content-slogan" style={{ marginTop: "10px" }}>
                        ContactUs: ipomoea@gmail.com
                    </div>
                </div>

                <div className="footer-content">
                    <a href="/">Home</a>
                    <a href="/whatwedo">What we do</a>
                    <a href="/aboutus">About us</a>
                    <a href="/marketplace">3D Market</a>
                    <a href="/privacypolicy">Terms</a>
                </div>
            </div>

            <div className="footer-copyright">
                <p>Copyright 2022. All rights Reserved</p>
            </div>
        </div>
    );
};

export default Footer;
