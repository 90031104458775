import React, { useEffect, useState } from "react";

import { Auth } from "aws-amplify";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogIn } from "../../redux/users/userSlice";
import { Footer, Navbar } from "../../components";

import "./loginpage.css";
import logo from "../../assets/Logo.png";

const LoginPage = () => {
    const isHomepage = false;
    const [data, setData] = useState({ username: "", password: "", email: "", code: "", new_password: "" });
    const [failLogin, setFailLogin] = useState(false);
    const [reset, setReset] = useState(false);
    const [resetError, setResetError] = useState(false);
    const [resetEmpty, setResetEmpty] = useState(false);
    const [codeEmpty, setCodeEmpty] = useState(false);
    const [codeInvalid, setCodeInvalid] = useState(false);
    const [new_passwordEmpty, setNew_passwordEmpty] = useState(false);
    const [new_passwordInvalid, setNew_passwordInvalid] = useState(false);
    const [limitError, setLimitError] = useState(false);
    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    const navigate = useNavigate();
    const dispatch = useDispatch();

    async function signIn() {
        const { username, password } = data;
        try {
            const user = await Auth.signIn(username, password);
            dispatch(userLogIn(user.attributes.email));
            console.log(user);
            navigate("/");
        } catch (error) {
            setFailLogin(true);
            console.log("error signing in", error);
        }
    }
    async function forgotPassword() {
        const { username } = data;
        Auth.forgotPassword(username)
            .then((data) => {
                setReset(true);
                console.log(data);
            })
            .catch((err) => {
                if (username === "") {
                    setResetEmpty(true);
                }
                if (err == "UserNotFoundException: Username/client id combination not found.") {
                    setResetError(true);
                    setFailLogin(false);
                    setResetEmpty(false);
                }

                console.log(err);
            });
    }

    async function confirmForgotPassword() {
        const { username, code, new_password } = data;
        if (code === "") {
            setCodeEmpty(true);
        } else {
            setCodeEmpty(false);
        }

        if (new_password === "") {
            setNew_passwordEmpty(true);
        } else {
            setNew_passwordEmpty(false);
        }
        Auth.forgotPasswordSubmit(username, code, new_password)
            .then((data) => {
                navigate("/");
                console.log(data);
            })
            .catch((err) => {
                if (err == "CodeMismatchException: Invalid verification code provided, please try again.") {
                    setCodeInvalid(true);
                } else {
                    setCodeInvalid(false);
                }
                if (err == "InvalidPasswordException: Password does not conform to policy: Password not long enough") {
                    setNew_passwordInvalid(true);
                } else {
                    setNew_passwordInvalid(false);
                }
                if (err == "LimitExceededException: Attempt limit exceeded, please try after some time.") {
                    setLimitError(true);
                } else {
                    setLimitError(false);
                }
                console.log(err);
            });
    }

    return (
        <div className="loginPage">
            <div className="gradient__bg">
                <Navbar HomepageOrNot={isHomepage} />
            </div>
            <div className="loginPage-content-container">
                <div className="loginPage-container">
                    <img src={logo} alt="login-logo" />
                    <h1>Welcome to Ipomoea</h1>
                    {reset ? null : (
                        <>
                            {failLogin ? (
                                <p className="loginPage-errorMessage">Invalid username or password, please try again</p>
                            ) : null}
                            {resetError ? (
                                <p className="loginPage-errorMessage">This username has not been registered</p>
                            ) : null}
                            {resetEmpty ? <p className="loginPage-errorMessage">Username cannot be empty</p> : null}
                            <input placeholder="Username" type="text" onChange={handleChange} name="username"></input>
                            <input
                                placeholder="Password"
                                type="password"
                                onChange={handleChange}
                                name="password"
                            ></input>
                            <div className="forgotPassword" onClick={forgotPassword}>
                                Forgot Password?
                            </div>

                            <button onClick={signIn}>Log In</button>
                            {/* <div className="register">OR</div>
                            <button>FaceBook</button>
                            <button>Google</button> */}
                            <div className="register">
                                Need an account? <a href="/signup">SIGN UP</a>
                            </div>
                        </>
                    )}
                    {reset ? (
                        <>
                            <h4 className="verificationSent">Verification code has been sent to your email account</h4>
                            {limitError ? (
                                <p className="loginPage-errorMessage">
                                    Attempt limit exceeded, please try after some time
                                </p>
                            ) : null}
                            <input
                                placeholder="Verification Code"
                                type="text"
                                onChange={handleChange}
                                name="code"
                            ></input>
                            {codeEmpty ? (
                                <p className="loginPage-errorMessage">Verification code cannot be empty</p>
                            ) : null}
                            {codeInvalid ? (
                                <p className="loginPage-errorMessage">Invalide code, please try again</p>
                            ) : null}
                            <input type="text" placeholder="New Password" onChange={handleChange} name="new_password" />
                            {new_passwordEmpty ? (
                                <p className="loginPage-errorMessage">New Password cannot be empty</p>
                            ) : null}
                            {new_passwordInvalid ? (
                                <p className="loginPage-errorMessage">
                                    Password should have at least 8 characters/numbers
                                </p>
                            ) : null}
                            <button style={{ marginTop: "30px" }} onClick={confirmForgotPassword}>
                                Reset Password
                            </button>
                        </>
                    ) : null}
                </div>
            </div>

            <div className="gradient__bg">
                <Footer />
            </div>
        </div>
    );
};

export default LoginPage;
