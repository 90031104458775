import React from "react";

import "./homepage.css";
import {
    Navbar,
    Header,
    Aboutus,
    Whatwedo,
    Footer,
    Partners,
    Slogan,
    Roadmap,
    Values,
    Slogan2,
    ContactUs,
} from "../../components";
const Homepage = () => {
    const scrollAnimate = (component) => {
        var scroll =
            window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.msRequestAnimationFrame ||
            window.oRequestAnimationFrame ||
            // IE Fallback, you can even fallback to onscroll
            function (callback) {
                window.setTimeout(callback, 1000 / 60);
            };
        var elementsToShow = document.querySelectorAll("." + component);

        let animateType = "fade-in-bottom";
        if (component === "whatwedo-image" || component === "values-content-values") {
            animateType = "fade-in-left";
        }
        if (component === "whatwedo-content" || component === "values-content-commitment") {
            animateType = "fade-in-right";
        }

        function loop() {
            elementsToShow.forEach(function (element) {
                if (isElementInViewport(element)) {
                    element.classList.add(animateType);
                } else {
                    element.classList.remove(animateType);
                }
            });

            scroll(loop);
        }

        // Call the loop for the first time
        loop();

        // Helper function from: http://stackoverflow.com/a/7557433/274826
        function isElementInViewport(el) {
            var rect = el.getBoundingClientRect();
            return (
                (rect.top <= 0 && rect.bottom >= 0) ||
                (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.top <= (window.innerHeight || document.documentElement.clientHeight)) ||
                (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
            );
        }
    };
    return (
        <div className="gradient__bg">
            <Navbar />
            <Header scrollAnimate={scrollAnimate} />
            <Slogan />
            <Whatwedo scrollAnimate={scrollAnimate} />
            <Slogan2 />
            <Aboutus scrollAnimate={scrollAnimate} />
            <Values scrollAnimate={scrollAnimate} />
            <ContactUs />
            <Partners />
            <Roadmap />

            <div className="gradient__bg demoPage_footer">
                <Footer />
            </div>
        </div>
    );
};

export default Homepage;
