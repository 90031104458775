import React, { useEffect } from "react";

import "./roadmap.scss";
const RoadMap = () => {
    useEffect(() => {
        var items = document.querySelectorAll(".timeline li");

        function isElementInViewport(el) {
            var rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }

        function callbackFunc() {
            for (var i = 0; i < items.length; i++) {
                if (isElementInViewport(items[i])) {
                    if (!items[i].classList.contains("in-view")) {
                        items[i].classList.add("in-view");
                    }
                } else if (items[i].classList.contains("in-view")) {
                    items[i].classList.remove("in-view");
                }
            }
        }

        window.addEventListener("load", callbackFunc);
        window.addEventListener("scroll", callbackFunc);
    }, []);

    return (
        <div className="roadmap section__padding">
            <div className="roadmap__feature-title">
                <div />
                <h1>RoadMap</h1>
            </div>
            <section className="timeline">
                <ul>
                    <li>
                        <div>
                            <time>Q4 2022</time>
                            <div className="discovery">
                                <h1>Angle Investment</h1>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div>
                            <time>Q1 2023</time>
                            <div className="discovery">
                                <h1>First Product Launch</h1>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div>
                            <time>Q2 2023</time>
                            <div className="discovery">
                                <h1>Finding Product-Market Fit</h1>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div>
                            <time>Q3 2023</time>
                            <div className="discovery">
                                <h1>Pre-seed Fundraising</h1>
                            </div>
                        </div>
                    </li>
                </ul>
            </section>
        </div>
    );
};

export default RoadMap;
