import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import {
    Homepage,
    Aboutuspage,
    WhatwedoPage,
    DemoPage,
    LoginPage,
    SignUpPage,
    DashBoard,
    MarketPlace,
    YMallPage,
    DiniWigsPage,
    TestPage,
    PrivacyPolicyPage,
    PrivacyPolicyContent,
} from "./pages";
import "./App.css";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route index path="/" element={<Homepage />} />
                    <Route path="/aboutus" element={<Aboutuspage />} />
                    <Route path="/whatwedo" element={<WhatwedoPage />} />
                    <Route path="/demo" element={<DemoPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/signup" element={<SignUpPage />} />
                    <Route path="/dashboard" element={<DashBoard />} />
                    <Route path="/testing" element={<TestPage />} />
                    <Route path="/marketplace" element={<MarketPlace />} />
                    <Route path="/ymall" element={<YMallPage />} />
                    <Route path="/diniwigs" element={<DiniWigsPage />} />
                    <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
                    <Route path="/privacypolicy/content" element={<PrivacyPolicyContent />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
