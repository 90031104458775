import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";

async function signOut() {
    try {
        await Auth.signOut();
        // setData({ ...data, step: 2 });
        // console.log(data);
    } catch (error) {
        console.log("error signing out: ", error);
    }
}
export const userSlice = createSlice({
    name: "user",
    initialState: {
        loggedIn: false,
        username: "",
    },
    reducers: {
        userLogIn: (state, action) => {
            state.loggedIn = true;
            state.username = action.payload;
        },
        userLogOut: (state) => {
            state.loggedIn = false;
            console.log(state.loggedIn);
        },
    },
});

export const { userLogIn, userLogOut } = userSlice.actions;

export default userSlice.reducer;
