import React, { useRef, useState } from "react";
import { useCursor, useGLTF } from "@react-three/drei";

const YMallGlasses = (props) => {
    const { name, useStore, glassesSelection } = props;
    const [hovered, setHovered] = useState(false);
    const group = useRef();
    useCursor(hovered);
    let url = "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/glasses/glasses.glb";
    switch (glassesSelection) {
        case "YMallGlasses1":
            url = "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/ymallGlasses/glasses1.glb";
            break;
        case "YMallGlasses2":
            url = "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/ymallGlasses/glasses2.glb";
            break;
        case "YMallGlasses3":
            url = "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/ymallGlasses/glasses3.glb";
            break;
        case "YMallGlasses4":
            url = "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/ymallGlasses/glasses4.glb";
            break;
        case "YMallGlasses5":
            url = "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/ymallGlasses/glasses5.glb";
            break;
        case "YMallGlasses6":
            url = "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/ymallGlasses/glasses6.glb";
            break;
        case "YMallGlasses7":
            url = "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/ymallGlasses/glasses7.glb";
            break;
        default:
            url = "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/glasses/glasses.glb";
    }
    const obj = useGLTF(url);
    console.log(obj);
    const { nodes, materials } = obj;
    const setTarget = useStore((state) => state.setTarget);
    return (
        <group
            // scale={9.9}
            ref={group}
            name={name}
            onClick={(e) => setTarget(group.current)}
            onPointerOver={() => setHovered(true)}
            onPointerOut={(e) => setHovered(false)}
        >
            {glassesSelection === "YMallGlasses1" ? (
                <mesh scale={[3, 3, 2.6]} position={[0, 2, 7]} rotation={[-0.08, 0, 0]}>
                    <primitive object={obj.scene} />
                </mesh>
            ) : null}
            {glassesSelection === "YMallGlasses2" ? (
                <mesh scale={[3, 3, 2.6]} position={[0, 2, 7]} rotation={[-0.08, 0, 0]}>
                    <primitive object={obj.scene} />
                </mesh>
            ) : null}
            {glassesSelection === "YMallGlasses3" ? (
                <mesh scale={[3, 3, 2.6]} position={[0, 2, 7]} rotation={[-0.08, 0, 0]}>
                    <primitive object={obj.scene} />
                </mesh>
            ) : null}
            {glassesSelection === "YMallGlasses4" ? (
                <mesh scale={[3, 3, 2.6]} position={[0, 2, 7]} rotation={[-0.08, 0, 0]}>
                    <primitive object={obj.scene} />
                </mesh>
            ) : null}
            {glassesSelection === "YMallGlasses5" ? (
                <mesh scale={[3, 3, 2.6]} position={[0, 2, 7]} rotation={[-0.08, 0, 0]}>
                    <primitive object={obj.scene} />
                </mesh>
            ) : null}
            {glassesSelection === "YMallGlasses6" ? (
                <mesh scale={[3, 3, 2.6]} position={[0, 2, 7]} rotation={[-0.08, 0, 0]}>
                    <primitive object={obj.scene} />
                </mesh>
            ) : null}
            {glassesSelection === "YMallGlasses7" ? (
                <mesh scale={[3, 3, 2.6]} position={[0, 2, 7]} rotation={[-0.08, 0, 0]}>
                    <primitive object={obj.scene} />
                </mesh>
            ) : null}
        </group>
    );
};

export default YMallGlasses;
