import React, { Suspense, useEffect, useState } from "react";
import { Canvas } from "@react-three/fiber";
import {
    ContactShadows,
    Environment,
    Html,
    OrbitControls,
    PerspectiveCamera,
    Text,
    TransformControls,
} from "@react-three/drei";
import { useControls } from "leva";
import create from "zustand";

import { Footer, Navbar, Glasses, Hair, Demo } from "../../components";
import "./demopage.css";
import Spinner from "../../components/common/Spinner";

const useStore = create((set) => ({ target: null, setTarget: (target) => set({ target }) }));

function Loader() {
    return (
        <Html center>
            <Spinner />
        </Html>
    );
}

const DemoPage = () => {
    const isHomepage = false;
    const isBodyModel = true;
    const { modes, glasses, hairStyles, eyes, background, clothes, pants } = useControls({
        modes: { value: "translate", options: ["translate", "rotate", "scale"] },
        glasses: {
            value: "none",
            options: [
                "Glasses1",
                "Glasses2",
                "Glasses3",
                "Glasses4",
                "Glasses5",
                "Glasses6",
                "Glasses7",
                "Glasses8",
                "Glasses9",
            ],
        },
        hairStyles: {
            value: "none",
            options: [
                "menHairStyle1",
                "menHairStyle2",
                "menHairStyle3",
                "menHairStyle4",
                "menHairStyle5",
                // "femaleHairStyle1",
                // "femaleHairStyle2",
                "femaleHairStyle3",
                "femaleHairStyle4",
                // "femaleHairStyle5",
            ],
        },
        eyes: {
            value: "brownEyes",
            options: ["brownEyes", "blueEyes", "darkBrownEyes", "greyEyes", "greenEyes", "hazelEyes"],
        },
        background: {
            value: "sunset",
            options: ["sunset", "forest", "city", "lobby", "apartment", "dawn", "studio", "park", "night", "warehouse"],
        },
        clothes: {
            value: "T-shirt1",
            options: ["T-shirt1", "T-shirt2"],
        },
        pants: {
            value: "pants1",
            options: ["pants1", "pants2"],
        },
        shoes: {
            value: "shoes1",
            options: ["shoes1"],
        },
    });

    const { target, setTarget } = useStore();

    return (
        <div className="demoPage">
            <div className="gradient__bg">
                <Navbar HomepageOrNot={isHomepage} />
            </div>
            <div className="demoPage_demo-container" style={{ height: window.innerHeight }}>
                <Canvas camera={{ position: [0, 10, 50] }} onPointerMissed={() => setTarget(null)}>
                    <PerspectiveCamera position={[0.1, 0.1, -0.2]}>
                        <ambientLight args={[0x222222]} intensity={1} />
                        <directionalLight color="0xffffff" position={[1, 0.75, 1]} intensity={1} />
                        <directionalLight color="0xffffff" position={[-1, 0.75, -0.5]} intensity={1} />
                        <directionalLight color="0xffffff" position={[-0.5, 0.5, -0.5]} intensity={1} />
                        <directionalLight color="0x666666" position={[0, -0.5, 1]} intensity={1} />
                        <directionalLight color="0xffffff" position={[0, 3, 40]} intensity={0.1} />
                        <Suspense fallback={<Loader />}>
                            <Environment
                                // files={
                                //     "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/background/ballroom_2k.hdr"
                                // }
                                preset={background}
                                ground={{ radius: 60, height: 20, scale: 600 }}
                                background={true}
                            />
                            <Demo
                                name="Demo"
                                modes={modes}
                                useStore={useStore}
                                eyeSelection={eyes}
                                hairSelection={hairStyles}
                                glassesSelection={glasses}
                                isBodyModel={isBodyModel}
                                clothesSelection={clothes}
                                pantsSelection={pants}
                            />
                            {/* <Environment preset={background} background /> */}
                            <ContactShadows
                                resolution={1024}
                                position={[0, 0, 0]}
                                scale={100}
                                blur={2}
                                opacity={1}
                                far={10}
                            />
                            {target && <TransformControls object={target} mode={modes} />}
                        </Suspense>
                        <OrbitControls makeDefault />
                    </PerspectiveCamera>
                </Canvas>
            </div>

            <div className="gradient__bg demoPage_footer">
                <Footer />
            </div>
        </div>
    );
};

export default DemoPage;
