import React, { Suspense, useEffect, useState } from "react";
import { Auth, Storage } from "aws-amplify";
import { BsPersonCircle, BsPersonBoundingBox } from "react-icons/bs";
import { Environment, OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";

import "./dashboard.css";
import { Footer, Navbar } from "../../components";
import ExternalDemo from "../../components/3Ddemo/ExternalDemo";

const Dashboard = () => {
    const isHomepage = false;
    const [tab, setTab] = useState({
        setting: "profile",
    });
    const [objUrl, setObjUrl] = useState("");
    const [albedoUrl, setAlbedoUrl] = useState("");
    const [normalUrl, setNormalUrl] = useState("");
    let content;
    // const getObjUrl = async (usernameUrl) => {
    //     try {
    //         await Storage.get(usernameUrl + "/head.glb", { level: "public" }) // for listing ALL files without prefix, pass '' instead
    //             .then((result) => {
    //                 setObjUrl(result.match(/https.*?(.glb)/)[0]);
    //                 console.log(result.match(/https.*?(.glb)/)[0]);
    //             })
    //             .catch((err) => console.log(err));
    //     } catch (error) {}
    // };
    // const getAlbedoUrl = async (usernameUrl) => {
    //     try {
    //         await Storage.get(usernameUrl + "/albedo.png", { level: "public" }) // for listing ALL files without prefix, pass '' instead
    //             .then((result) => {
    //                 setAlbedoUrl(result.match(/https.*?(.png)/)[0]);
    //             })
    //             .catch((err) => console.log(err));
    //     } catch (error) {}
    // };
    // const getNormalUrl = async (usernameUrl) => {
    //     try {
    //         await Storage.get(usernameUrl + "/normal.png", { level: "public" }) // for listing ALL files without prefix, pass '' instead
    //             .then((result) => {
    //                 setNormalUrl(result.match(/https.*?(.png)/)[0]);
    //             })
    //             .catch((err) => console.log(err));
    //     } catch (error) {}
    // };
    const getObjUrl = async (usernameUrl) => {
        try {
            await Storage.get("head.glb", { level: "private", identityId: usernameUrl, download: "true" }) // for listing ALL files without prefix, pass '' instead
                .then((result) => {
                    setObjUrl(result.match(/https.*?(.glb)/)[0]);
                    console.log(result.match(/https.*?(.glb)/)[0]);
                })
                .catch((err) => console.log(err));
        } catch (error) {}
    };
    const getAlbedoUrl = async (usernameUrl) => {
        try {
            await Storage.get("albedo.png", { level: "private", identityId: usernameUrl }) // for listing ALL files without prefix, pass '' instead
                .then((result) => {
                    setAlbedoUrl(result.match(/https.*?(.png)/)[0]);
                })
                .catch((err) => console.log(err));
        } catch (error) {}
    };
    const getNormalUrl = async (usernameUrl) => {
        try {
            await Storage.get("normal.png", { level: "private", identityId: usernameUrl }) // for listing ALL files without prefix, pass '' instead
                .then((result) => {
                    setNormalUrl(result.match(/https.*?(.png)/)[0]);
                })
                .catch((err) => console.log(err));
        } catch (error) {}
    };

    const currentUser = async () => {
        const user = await Auth.currentAuthenticatedUser();
        console.log(user.storage);
        const { username } = user;
        getObjUrl(username);
        getAlbedoUrl(username);
        getNormalUrl(username);
    };
    useEffect(() => {
        currentUser();

        // console.log(currentUser().then((result) => console.log(result)));
    }, []);

    switch (tab.setting) {
        case "profile":
            content = (
                <div className="dashboardPage-profileContent">
                    Balloons are pretty and come in different colors, different shapes, different sizes, and they can
                    even adjust sizes as needed. But don't make them too big or they might just pop, and then bye-bye
                    balloon. It'll be gone and lost for the rest of mankind. They can serve a variety of purposes, from
                    decorating to water balloon wars. You just have to use your head to think a little bit about what to
                    do with them. It seemed like it should have been so simple. There was nothing inherently difficult
                    with getting the project done. It was simple and straightforward enough that even a child should
                    have been able to complete it on time, but that wasn't the case. The deadline had arrived and the
                    project remained unfinished.
                    <br />
                    <br />
                    The rain and wind abruptly stopped, but the sky still had the gray swirls of storms in the distance.
                    Dave knew this feeling all too well. The calm before the storm. He only had a limited amount of time
                    before all Hell broke loose, but he stopped to admire the calmness. Maybe it would be different this
                    time, he thought, with the knowledge deep within that it wouldn't. The day had begun on a bright
                    note. The sun finally peeked through the rain for the first time in a week, and the birds were
                    singing in its warmth. There was no way to anticipate what was about to happen. It was a worst-case
                    scenario and there was no way out of it.
                </div>
            );
            break;
        case "models":
            content = (
                <>
                    <Canvas camera={{ position: [0, 10, 50] }}>
                        <ambientLight args={[0x222222]} intensity={1} />
                        <directionalLight color="0xffffff" position={[1, 0.75, 1]} intensity={1} />
                        <directionalLight color="0xffffff" position={[-1, 0.75, -0.5]} intensity={2} />
                        <directionalLight color="0xffffff" position={[-0.5, 0.5, -0.5]} intensity={1.5} />
                        <directionalLight color="0x666666" position={[0, -0.5, 1]} intensity={1} />
                        <Suspense fallback={null}>
                            {objUrl && albedoUrl && normalUrl && (
                                <ExternalDemo objUrl={objUrl} albedoUrl={albedoUrl} normalUrl={normalUrl} />
                            )}
                            <Environment preset="sunset" background />
                            <OrbitControls makeDefault />
                        </Suspense>
                    </Canvas>
                </>
            );
            break;
        default:
            content = <></>;
    }
    return (
        <div className="dashboardPage">
            <div className="gradient__bg">
                <Navbar HomepageOrNot={isHomepage} />
            </div>
            <div className="dashboardPage-contentContainer">
                <div className="dashboardPage-sidebar">
                    <button onClick={() => setTab({ ...tab, setting: "profile" })}>
                        <BsPersonCircle />
                        &nbsp;&nbsp;Profile
                    </button>
                    <button onClick={() => setTab({ ...tab, setting: "models" })}>
                        <BsPersonBoundingBox />
                        &nbsp;&nbsp;Models
                    </button>
                </div>
                <div className="dashboardPage-content">{content}</div>
            </div>

            <div className="gradient__bg">
                <Footer />
            </div>
        </div>
    );
};

export default Dashboard;
