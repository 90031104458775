import React from "react";
import * as THREE from "three";
import { useLoader } from "@react-three/fiber";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { TextureLoader } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const ExternalDemo = (props) => {
    const { objUrl, albedoUrl, normalUrl } = props;
    // const material = new THREE.MeshStandardMaterial({ color: "rgb(152,122,107)", wireframe: false });
    // const obj = useLoader(OBJLoader, objUrl);
    const obj = useLoader(GLTFLoader, objUrl);
    console.log(obj);

    const texture = useLoader(TextureLoader, albedoUrl);
    texture.flipY = false;
    const normalMap = useLoader(TextureLoader, normalUrl);
    normalMap.flipY = false;
    normalMap.encoding = THREE.sRGBEncoding;

    const normalScale = new THREE.Vector2(0.5, 0.5);
    const material = new THREE.MeshPhongMaterial({
        specular: 0x111111,
        shininess: 20,
        // specularMap: specularTexture,
        normalScale: normalScale,
        map: texture,
        normalMap: normalMap,
    });
    // obj.children[0].material = material;
    // obj.children[0].material.map = texture;
    // obj.children[0].material.flatShading = true;
    // obj.children[0].material.normalMap = normalMap;
    // obj.children[0].position.y = 15;
    return (
        // <mesh
        //     geometry={obj.children[0].geometry}
        //     scale={1}
        //     // material={material}
        //     // onClick={(e) => (e.stopPropagation(), (state.current = name))}

        //     // dispose={null}
        // >
        //     <meshPhongMaterial
        //         map={texture}
        //         normalScale={normalScale}
        //         normalMap={normalMap}
        //         specular={0x111111}
        //         shininess={20}
        //         flatShading={true}
        //     />
        // </mesh>
        <mesh
            geometry={obj.scene.children[0].geometry}
            material={obj.materials["Default OBJ"]}
            rotation={[1.5, 0, 0]}
            scale={1}
            position={[0, -0.2, 0.3]}
        >
            <meshPhongMaterial
                map={texture}
                normalScale={normalScale}
                normalMap={normalMap}
                specular={0x111111}
                shininess={20}
            />
        </mesh>
    );
};

export default ExternalDemo;
