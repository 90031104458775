import React from "react";

import "./contactus.style.scss";

const ContactUs = () => {
    return (
        <div className="contactus-container">
            <div className="contactus-container-content">
                <div className="contactus-slogan">
                    <div className="contactus-slogan-title">Get our free information pack</div>
                    <div>Find out more about the Ipomoea, subscribe for our latest updates!</div>
                </div>
                {/* <div>
                    <div className="contactus-user-box">
                        <input type="text" name="" required="" />
                        <label>Username</label>
                    </div>
                </div> */}
                <div className="contactus-button-wrapper">
                    <a className="contactus-button" href="#contactus">
                        Contact Us
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
