import React from "react";
import { BiLinkExternal } from "react-icons/bi";

import "./partners.css";
import commingsoon from "../../assets/commingsoon.jpg";
import YMall from "../../assets/YMall.png";
import aws from "../../assets/aws.png";
import onDeck from "../../assets/onDeck.png";
import Knitwise from "../../assets/Knitwise.jpg";
import DiniWigs from "../../assets/DiniWigs.png";
import Bbase from "../../assets/Bbase.png";
const Partners = () => {
    return (
        <div>
            <div className="partners">
                <div className="partners-content-container">
                    <h1 className="partners-title">Partners & Investors</h1>
                    <div className="partners-content">
                        <div className="partners-link" style={{ marginRight: "70%" }}>
                            <a href="https://www.beondeck.com/">
                                <div className="partners-link-inner">
                                    <img src={onDeck} alt="onDeck" />
                                    <div className="partners-link-name">
                                        <p>On Deck</p>&nbsp;
                                        <BiLinkExternal />
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="partners-link" style={{ marginLeft: "70%" }}>
                            <a href="https://www.instagram.com/diniwigs/">
                                <div className="partners-link-inner">
                                    <img src={DiniWigs} alt="diniwigs" />
                                    <div className="partners-link-name">
                                        <p>DiniWigs</p>&nbsp;
                                        <BiLinkExternal />
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="partners-link">
                            <a href="https://www.ebay.com.au/str/lavandeonline2013/Fascinators-Hair-Products/_i.html?store_cat=37583205018">
                                <div className="partners-link-inner">
                                    <img src={YMall} alt="YMall" />
                                    <div className="partners-link-name">
                                        <p>Y Mall</p>&nbsp;
                                        <BiLinkExternal />
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div className="partners-link" style={{ marginRight: "50%" }}>
                            <a href="https://www.knitwise.com/">
                                <div className="partners-link-inner">
                                    <img src={Knitwise} alt="Knitwise" />
                                    <div className="partners-link-name">
                                        <p>Knitwise</p>&nbsp;
                                        <BiLinkExternal />
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div>
                            <div className="partners-link" style={{ marginLeft: "30%" }}>
                                <a href="https://bbase.com.hk/">
                                    <div className="partners-link-inner">
                                        <img src={Bbase} alt="Bbase" />
                                        <div className="partners-link-name">
                                            <p>Bbase</p>&nbsp;
                                            <BiLinkExternal />
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div>
                            <div className="partners-link" style={{ marginRight: "70%" }}>
                                <a href="https://aws.amazon.com/startups/startup-programs/?nc1=h_ls">
                                    <div className="partners-link-inner">
                                        <img src={aws} alt="aws" />
                                        <div className="partners-link-name">
                                            <p>AWS Startups</p>&nbsp;
                                            <BiLinkExternal />
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Partners;
