import { Environment, Html, OrbitControls, PerspectiveCamera, TransformControls } from "@react-three/drei";
import { Canvas, useLoader } from "@react-three/fiber";
import React, { Suspense, useRef, useState } from "react";
import { useControls } from "leva";
import create from "zustand";
import { useCursor } from "@react-three/drei";
import * as THREE from "three";

import Spinner from "../../components/common/Spinner";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const useStore = create((set) => ({ target: null, setTarget: (target) => set({ target }) }));
function Loader() {
    return (
        <Html center>
            <Spinner />
        </Html>
    );
}

const Model = (props) => {
    const bodyObj = useLoader(
        GLTFLoader,
        "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/body/body.glb"
    );
    const pantsObj = useLoader(
        GLTFLoader,
        "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/body/kuzi.glb"
    );
    const clothesObj = useLoader(
        GLTFLoader,
        "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/body/shangyi.glb"
    );
    console.log(bodyObj);
    const { modes } = props;
    const group = useRef();
    const setTarget = useStore((state) => state.setTarget);
    const [hovered, setHovered] = useState(false);
    useCursor(hovered);
    const material = new THREE.MeshPhongMaterial({
        map: bodyObj.materials.face.map,
        normalMap: bodyObj.materials.face.normalMap,
    });
    const armMaterial = new THREE.MeshPhongMaterial({
        map: bodyObj.materials.arm.map,
    });
    const legMaterial = new THREE.MeshPhongMaterial({
        map: bodyObj.materials.leg.map,
    });
    const clothesMaterial = new THREE.MeshPhongMaterial({
        map: clothesObj.materials.shangyi.map,
    });
    const pantsMaterial = new THREE.MeshPhongMaterial({
        map: pantsObj.materials.kuzi.map,
    });
    return (
        <group
            scale={30}
            ref={group}
            onClick={(e) => (setTarget(group.current), console.log(e))}
            onPointerOver={() => setHovered(true)}
            onPointerOut={(e) => setHovered(false)}
        >
            <group>
                <mesh
                    geometry={bodyObj.scene.children[0].children[0].geometry}
                    material={bodyObj.materials.shoe}
                    position={[0, -10, 0]}
                />
                <mesh
                    geometry={bodyObj.scene.children[0].children[1].geometry}
                    material={legMaterial}
                    position={[0, -10, 0]}
                />
                <mesh
                    geometry={bodyObj.scene.children[0].children[2].geometry}
                    material={bodyObj.materials.body}
                    position={[0, -10, 0]}
                />
                <mesh
                    geometry={bodyObj.scene.children[0].children[3].geometry}
                    material={armMaterial}
                    position={[0, -10, 0]}
                />
                <mesh
                    geometry={bodyObj.scene.children[0].children[4].geometry}
                    material={material}
                    position={[0, 0.158, 0]}
                    scale={[1, 0.9, 1]}
                >
                    {/* <meshPhongMaterial material={bodyObj.materials.face} /> */}
                </mesh>
            </group>
            <mesh geometry={pantsObj.scene.children[0].geometry} material={pantsMaterial}></mesh>
            <mesh geometry={clothesObj.scene.children[0].geometry} material={clothesMaterial}></mesh>
        </group>
    );
};

const TestPage = () => {
    const { target, setTarget } = useStore();
    const { modes } = useControls({
        modes: { value: "translate", options: ["translate", "rotate", "scale"] },
    });
    return (
        <div className="demoPage_demo-container" style={{ height: window.innerHeight }}>
            <Canvas camera={{ position: [0, 10, 50] }} onPointerMissed={() => setTarget(null)}>
                <PerspectiveCamera position={[0.1, 0.1, -0.2]}>
                    <ambientLight args={[0x222222]} intensity={1} />
                    <directionalLight color="0xffffff" position={[1, 0.75, 1]} intensity={1} />
                    <directionalLight color="0xffffff" position={[-1, 0.75, -0.5]} intensity={1} />
                    <directionalLight color="0xffffff" position={[-0.5, 0.5, -0.5]} intensity={1} />
                    <directionalLight color="0x666666" position={[0, -0.5, 1]} intensity={1} />
                    <Suspense fallback={<Loader />}>
                        <Model modes={modes} useStore={useStore} />

                        <Environment preset="forest" background />
                        {target && <TransformControls object={target} mode={modes} />}
                    </Suspense>
                    <OrbitControls makeDefault />
                </PerspectiveCamera>
            </Canvas>
        </div>
    );
};

export default TestPage;
