import React from "react";

import "./slogan.scss";
const Slogan = () => {
    return (
        <div className="slogan">
            <div className="slogan-content">
                <h1>
                    See your <span className="slogan-highlight">Virtual Try-on</span> result before purchasing
                </h1>
                <p>Simply scan & upload your photos</p>
            </div>
        </div>
    );
};

export default Slogan;
