import React, { useEffect, useState } from "react";
import { Footer, Navbar } from "../../components";
import logo from "../../assets/Logo.png";

import "./signuppage.css";
import { Auth } from "aws-amplify";
import { Navigate, useNavigate } from "react-router-dom";

const SignUpPage = () => {
    const isHomepage = false;
    const navigate = useNavigate();
    const [data, setData] = useState({
        username: "",
        password: "",
        email: "",
        confirmPassword: "",
        code: "",
    });
    const [userNameError, setUserNameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmpasswordError, setConfirmPasswordError] = useState(false);
    const [userExist, setUserExist] = useState(false);
    const [registerSuccess, setRegisterSuccess] = useState(false);
    const [verifyError, setVerifyError] = useState(false);
    const [verifySuccess, setVerifySuccess] = useState(false);
    const [resendSuceess, setResendSuccess] = useState(false);

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    async function signUp() {
        try {
            const { username, password, email } = data;
            const { user } = await Auth.signUp({
                username,
                password,
                attributes: {
                    email, // optional
                    // other custom attributes
                },
                autoSignIn: {
                    // optional - enables auto sign in after user is confirmed
                    enabled: true,
                },
            });
            // console.log(user);
            setRegisterSuccess(true);
            console.log("successfully signed up");
        } catch (error) {
            if (error == "UsernameExistsException: An account with the given email already exists.") {
                setUserExist(true);
            }

            console.log("error signing up:", error);
        }
    }

    async function confirmSignUp() {
        const { username, code } = data;
        try {
            await Auth.confirmSignUp(username, code);
            setVerifySuccess(true);
            setVerifyError(false);
        } catch (error) {
            setVerifyError(true);
            console.log("error confirming sign up", error);
        }
    }

    async function resendConfirmationCode() {
        const { username } = data;
        try {
            await Auth.resendSignUp(username);
            setResendSuccess(true);
            console.log("code resent successfully");
        } catch (err) {
            console.log("error resending code: ", err);
        }
    }

    useEffect(() => {
        if (verifySuccess) {
            setTimeout(() => {
                navigate("/login");
            }, 5000);
        }
    }, [verifySuccess]);

    const confirmContent = () => {
        return (
            <>
                <p className="verifySuccess">
                    Successfully registered! <br />
                    Verification code has been sent to your email account
                </p>
                <input placeholder="Verification Code" type="text" name="code" onChange={handleChange} />
                {verifyError ? (
                    <div className="signupPage-errorMessage">
                        <p>Invalid verification code, please try again</p>
                    </div>
                ) : null}

                <button onClick={confirmSignUp}>Confirm Code</button>
                <button onClick={resendConfirmationCode}>Resend Code</button>
                {resendSuceess ? (
                    <div className="verifySuccess">
                        <p>Successfully resend code</p>
                    </div>
                ) : null}
                {verifySuccess ? (
                    <div className="verifySuccess">
                        <p>
                            Successfully verified!
                            <br /> Redirecting to Log In page......
                        </p>
                    </div>
                ) : null}
            </>
        );
    };

    const register = () => {
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (data.password.length < 8) {
            setPasswordError(true);
        } else {
            setPasswordError(false);
        }
        if (data.password !== data.confirmPassword) {
            setConfirmPasswordError(true);
        } else {
            setConfirmPasswordError(false);
        }
        if (data.username.match(mailformat)) {
            setUserNameError(false);
        } else {
            setUserNameError(true);
        }
        // if (data.email.match(mailformat)) {
        //     setEmailError(false);
        // } else {
        //     setEmailError(true);
        // }
        signUp();
    };

    return (
        <div className="signupPage">
            <div className="gradient__bg">
                <Navbar HomepageOrNot={isHomepage} />
            </div>

            <div className="signupPage-content-container">
                <div className="signupPage-container">
                    <img src={logo} alt="login-logo" />
                    <h1>Welcome to Ipomoea</h1>
                    {registerSuccess ? null : (
                        <>
                            <input placeholder="Username" type="text" onChange={handleChange} name="username"></input>
                            {userNameError ? (
                                <div className="signupPage-errorMessage">
                                    <p>Invalid username, must be an email address</p>
                                </div>
                            ) : null}
                            {userExist ? (
                                <div className="signupPage-errorMessage">
                                    <p>The given email already registered.</p>
                                </div>
                            ) : null}
                            <input
                                placeholder="Password"
                                type="password"
                                onChange={handleChange}
                                name="confirmPassword"
                            ></input>
                            {passwordError ? (
                                <div className="signupPage-errorMessage">
                                    <p>Password should have at least 8 characters/numbers</p>
                                </div>
                            ) : null}
                            <input
                                placeholder="Confirm Password"
                                type="password"
                                onChange={handleChange}
                                name="password"
                            ></input>
                            {confirmpasswordError ? (
                                <div className="signupPage-errorMessage">
                                    <p>Password should be same</p>
                                </div>
                            ) : null}
                            <button onClick={register}>Register</button>
                        </>
                    )}
                    {/* <input placeholder="Email" type="email" onChange={handleChange} name="email"></input>
                    {emailError ? (
                        <div className="signupPage-errorMessage">
                            <p>Invalid email address</p>
                        </div>
                    ) : null} */}

                    {/* <button>Google</button> */}
                    {registerSuccess ? confirmContent() : null}
                </div>
            </div>
            <div className="gradient__bg">
                <Footer />
            </div>
        </div>
    );
};

export default SignUpPage;
