import { Auth } from "aws-amplify";
import React, { useState, useEffect } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import logo from "../../assets/Logo.png";
import { userLogOut } from "../../redux/users/userSlice";
import "./navbar.css";

const Navbar = (props) => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const [isHomepage, setIsHomepage] = useState(true);
    const { HomepageOrNot } = props;
    const navigate = useNavigate();
    const loggedIn = useSelector((state) => state.user.loggedIn);
    const loggedInAs = useSelector((state) => state.user.username);
    const dispatch = useDispatch();

    useEffect(() => {
        if (HomepageOrNot !== undefined) {
            setIsHomepage(HomepageOrNot);
        }
    }, []);
    async function signOut() {
        try {
            await Auth.signOut();
            dispatch(userLogOut());
            // setData({ ...data, step: 2 });
            // console.log(data);
            navigate("/");
        } catch (error) {
            console.log("error signing out: ", error);
        }
    }
    const Menu = () => {
        return (
            <>
                <p>
                    <a href="#home">Home</a>
                </p>
                <p>
                    <a href="#whatwedo">What We Do?</a>
                </p>
                <p>
                    <a href="#aboutus">About us</a>
                </p>
                <p>
                    <a href="#contactus">Contact us</a>
                </p>
                <p>
                    <a href="/marketplace">3D Market</a>
                </p>
                {/* <p>
                    {loggedIn ? (
                        <h4 className="loggedInAs" onClick={() => navigate("/dashboard")}>
                            LoggedIn as: {loggedInAs}
                        </h4>
                    ) : null}
                </p>
                {loggedIn ? (
                    <p>
                        <Link to={""} className="logoutLink" onClick={signOut}>
                            Log Out
                        </Link>
                    </p>
                ) : (
                    <p>
                        <a href="/login">Log In</a>
                    </p>
                )} */}
            </>
        );
    };
    const MenuHome = () => {
        return (
            <>
                <p>
                    <a href="/">Home</a>
                </p>
                <p>
                    <a href="/whatwedo">What We Do?</a>
                </p>
                <p>
                    <a href="/aboutus">About us</a>
                </p>
                <p>
                    <a href="#contactus">Contact us</a>
                </p>
                <p>
                    <a href="/marketplace">3D Market</a>
                </p>
                {/* {loggedIn ? (
                    <h4 className="loggedInAs" onClick={() => navigate("/dashboard")}>
                        LoggedIn as: {loggedInAs}
                    </h4>
                ) : null}
                {loggedIn ? (
                    <p>
                        <Link to={""} className="logoutLink" onClick={signOut}>
                            Log Out
                        </Link>
                    </p>
                ) : (
                    <p>
                        <a href="/login">Log In</a>
                    </p>
                )} */}
            </>
        );
    };
    return (
        <div className="navbar">
            <div className="navbar-links">
                <div
                    className="navbar-links_logo"
                    onClick={() => {
                        navigate("/");
                    }}
                >
                    <img src={logo} alt="logo" />
                    <h1>Ipomoea</h1>
                </div>
            </div>
            <div className="navbar-links_container">{isHomepage ? <Menu /> : <MenuHome />}</div>
            <div className="navbar-menu">
                {toggleMenu ? (
                    <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
                ) : (
                    <RiMenu3Line color="fff" size={27} onClick={() => setToggleMenu(true)} />
                )}
                {toggleMenu && (
                    <div className="navbar-menu_container scale-up-center">
                        <div className="navbar-menu_container-links">
                            <MenuHome />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Navbar;
