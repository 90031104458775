import React, { useEffect } from "react";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import WhatwedoImg from "../../assets/Whatwedo.png";
import "./whatwedo.css";

const Whatwedo = (props) => {
    const navigate = useNavigate();
    useEffect(() => {
        props.scrollAnimate("whatwedo-image");
        props.scrollAnimate("whatwedo-content");
    }, []);
    return (
        <div className="whatwedo section__padding" id="whatwedo">
            <div className="whatwedo__feature-title">
                <div />
                <h1>What we do?</h1>
            </div>
            <div className="whatwedo-content-container">
                <div className="whatwedo-image">
                    <img src={WhatwedoImg} alt="whatwedo-Img" />
                </div>
                <div className="whatwedo-content">
                    {/* <div className="whatwedo-content-1">
                        <p>Want an immersive, interactive virtual shopping experience?</p>
                        <p>Wondering how you will look after wearing new clothing or accessories?</p>
                        <p>Want to know whether the clothing size fits or not?</p>
                    </div> */}
                    <div className="whatwedo-content-2">
                        <p>
                            Discover a new 3D personalized customer experience with Ipomoea using the world-leading
                            virtual try-on system to see yourself in any item of clothing or accessories instantly and
                            realistically.
                        </p>
                    </div>

                    <div className="whatwedo-content-button-wrapper">
                        <a className="whatwedo-content-button" href="/whatwedo">
                            See How It Works &nbsp;&nbsp;
                            <BsFillArrowRightCircleFill />
                        </a>
                    </div>
                </div>
            </div>
        </div>
        // </div>
    );
};

export default Whatwedo;
