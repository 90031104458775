import React, { useRef, useState } from "react";
import { useLoader } from "@react-three/fiber";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { TextureLoader } from "three";
import { useCursor } from "@react-three/drei";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import Hair from "./Hair";
import Glasses from "./Glasses";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

const Demo = (props) => {
    const group = useRef();
    const {
        useStore,
        name,
        eyeSelection,
        isBodyModel,
        hairSelection,
        glassesSelection,
        clothesSelection,
        pantsSelection,
    } = props;
    const dracoloader = new DRACOLoader();
    const normalScale = new THREE.Vector2(0.5, 0.5);
    const obj = useLoader(
        GLTFLoader,
        "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/body/body.glb"
    );

    const ClothesModel = () => {
        let clothesUrl;
        switch (clothesSelection) {
            case "T-shirt1":
                clothesUrl = "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/body/clothes1.glb";
                break;
            case "T-shirt2":
                clothesUrl = "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/body/clothes2.glb";
                break;
            default:
                ClothesModel = null;
        }
        const clothesObj = useLoader(GLTFLoader, clothesUrl, (loader) => {
            dracoloader.setDecoderPath("/draco-gltf/");
            loader.setDRACOLoader(dracoloader);
        });
        const clothesMaterial = new THREE.MeshPhongMaterial({
            map: clothesObj.materials.shangyi.map,
            normalScale: { x: 1, y: -1 },
        });
        return <mesh geometry={clothesObj.scene.children[0].geometry} material={clothesMaterial}></mesh>;
    };
    const PantsModel = () => {
        const pantsObj = useLoader(
            GLTFLoader,
            "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/body/pants1.glb"
        );
        const pantsMaterial = new THREE.MeshPhongMaterial({
            map: pantsObj.materials["kuzi.001"].map,
            normalMap: pantsObj.materials["kuzi.001"].normalMap,
            normalScale: { x: 1, y: -1 },
        });
        return <mesh geometry={pantsObj.scene.children[0].geometry} material={pantsMaterial}></mesh>;
    };
    const PantsModel2 = () => {
        const pantsObj = useLoader(
            GLTFLoader,
            "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/body/pants2.glb"
        );
        const pantsMaterial = new THREE.MeshPhongMaterial({
            map: pantsObj.materials["kuzi.003"].map,
            normalMap: pantsObj.materials["kuzi.003"].normalMap,
            normalScale: { x: 1, y: -1 },
        });
        return <mesh geometry={pantsObj.scene.children[0].geometry} material={pantsMaterial}></mesh>;
    };

    const faceMaterial = new THREE.MeshPhongMaterial({
        // color: "rgb(154,122,107)",
        specular: 0x111111,
        shininess: 20,
        normalScale: { x: 1, y: -1 },
        map: obj.materials.face.map,
        normalMap: obj.materials.face.normalMap,
    });
    const bodyMaterial = new THREE.MeshPhongMaterial({
        specular: 0x111111,
        shininess: 20,
        normalScale: { x: 1, y: -1 },
        map: obj.materials.body.map,
        normalMap: obj.materials.body.normalMap,
    });
    const armMaterial = new THREE.MeshPhongMaterial({
        map: obj.materials.arm.map,
    });
    const legMaterial = new THREE.MeshPhongMaterial({
        map: obj.materials.leg.map,
    });

    const shoesObj = useLoader(
        GLTFLoader,
        "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/body/shoe.glb"
    );

    const shoesMaterial = new THREE.MeshPhongMaterial({
        map: shoesObj.materials.shoe.map,
    });

    const eyeObj = useLoader(
        FBXLoader,
        "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/demoeyes/Realtime_Eyes.fbx"
    );
    let eyeAlbedo;
    switch (eyeSelection) {
        case "blueEyes":
            eyeAlbedo =
                "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/demoeyes/blueEye_light_albedo_1k.png";
            break;
        case "brownEyes":
            eyeAlbedo =
                "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/demoeyes/brownEye_light_albedo_1k.png";
            break;
        case "darkBrownEyes":
            eyeAlbedo =
                "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/demoeyes/darkBrownEye_light_albedo_1k.png";
            break;
        case "greenEyes":
            eyeAlbedo =
                "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/demoeyes/greenEye_light_albedo_1k.png";
            break;
        case "greyEyes":
            eyeAlbedo =
                "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/demoeyes/greyEye_light_albedo_1k.png";
            break;
        case "hazelEyes":
            eyeAlbedo =
                "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/demoeyes/hazelEye_light_albedo_1k.png";
            break;
        default:
            eyeAlbedo =
                "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/demoeyes/brownEye_light_albedo_1k.png";
    }
    const eyeMapTexture = useLoader(TextureLoader, eyeAlbedo);
    const eyeMaterial = new THREE.MeshLambertMaterial({
        map: eyeMapTexture,
    });

    const setTarget = useStore((state) => state.setTarget);
    const [hovered, setHovered] = useState(false);
    useCursor(hovered);
    return (
        <group
            ref={group}
            onClick={(e) => (setTarget(group.current), console.log(e))}
            onPointerOver={() => setHovered(true)}
            onPointerOut={(e) => setHovered(false)}
            // position={[0, 5, 0]}
        >
            <group scale={25} position={[0, -25, 0]}>
                <mesh geometry={obj.scene.children[0].children[0].geometry} material={legMaterial} />
                <mesh geometry={obj.scene.children[0].children[1].geometry} material={bodyMaterial} />
                <mesh geometry={obj.scene.children[0].children[2].geometry} material={armMaterial} />
                <mesh geometry={obj.scene.children[0].children[3].geometry} material={faceMaterial}></mesh>
                <ClothesModel />
                {pantsSelection === "pants1" ? <PantsModel /> : null}
                {pantsSelection === "pants2" ? <PantsModel2 /> : null}
                <mesh geometry={shoesObj.scene.children[0].geometry} material={shoesMaterial}></mesh>
            </group>

            <group scale={0.3}>
                <mesh geometry={eyeObj.children[0].geometry} material={eyeMaterial} position={[-2.8, 62.7, 5.2]}>
                    <meshLambertMaterial map={eyeMapTexture} />
                </mesh>
                <mesh geometry={eyeObj.children[0].geometry} material={eyeMaterial} position={[3.05, 62.7, 5.15]}>
                    <meshLambertMaterial map={eyeMapTexture} />
                </mesh>
            </group>
            {hairSelection !== "none" ? (
                <Hair name="Hair1" hairSelection={hairSelection} isBodyModel={isBodyModel} />
            ) : null}
            {glassesSelection !== "none" ? (
                <Glasses
                    name="Glasses"
                    glassesSelection={glassesSelection}
                    isBodyModel={isBodyModel}
                    useStore={useStore}
                />
            ) : null}
        </group>
    );
};

export default Demo;
