import React from "react";
import { useNavigate } from "react-router-dom";
import { Footer, Navbar } from "../../components";

import "./marketplace.scss";

const MarketPlace = () => {
    const isHomepage = false;
    const navigate = useNavigate();
    const mockData = [
        {
            id: 1,
            url: "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/mockImage/demo.jpg",
            name: "Demo",
            link: "/demo",
            background: "sunset",
        },
        {
            id: 2,
            url: "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/mockImage/3D+glasses.jpg",
            name: "Y Mall",
            link: "/ymall",
            background: "studio",
        },
        {
            id: 3,
            url: "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/mockImage/wigs.png",
            name: "Dini Wigs",
            link: "/diniwigs",
            background: "lobby",
        },
        {
            id: 4,
            url: "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/mockImage/commingsoon.jpg",
            name: "Comming Soon",
            link: "/marketplace",
            background: null,
        },
        {
            id: 5,
            url: "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/mockImage/commingsoon.jpg",
            name: "Comming Soon",
            link: "/marketplace",
            background: null,
        },
        {
            id: 6,
            url: "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/mockImage/commingsoon.jpg",
            name: "Comming Soon",
            link: "/marketplace",
            background: null,
        },
        {
            id: 7,
            url: "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/mockImage/commingsoon.jpg",
            name: "Comming Soon",
            link: "/marketplace",
            background: null,
        },
        {
            id: 8,
            url: "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/mockImage/commingsoon.jpg",
            name: "Comming Soon",
            link: "/marketplace",
            background: null,
        },
    ];
    return (
        <div className="glassesRetailPage">
            <div className="gradient__bg">
                <Navbar HomepageOrNot={isHomepage} />
            </div>
            <div>
                <div className="glassesRetailPage-title-container">
                    <div className="glassesRetailPage-title">3D Market</div>
                    <div className="glassesRetailPage-cube"></div>
                    <div className="glassesRetailPage-cube"></div>
                    <div className="glassesRetailPage-cube"></div>
                    <div className="glassesRetailPage-cube"></div>
                    <div className="glassesRetailPage-cube"></div>
                    <div className="glassesRetailPage-cube"></div>
                </div>
                <div className="glassesRetailPage-product-container">
                    {mockData.map((i) => {
                        return (
                            <div
                                className="productCard-container"
                                key={i.id}
                                onClick={() => {
                                    navigate(i.link, { state: i.background });
                                }}
                            >
                                <img src={i.url} alt={`${i.name}`} />
                                <span className="productCard-name">{i.name}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="gradient__bg demoPage_footer">
                <Footer />
            </div>
        </div>
    );
};

export default MarketPlace;
