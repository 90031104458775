import React, { useEffect } from "react";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import AboutusImg from "../../assets/aboutus.jpg";
import "./aboutus.scss";

const Aboutus = (props) => {
    const navigate = useNavigate();
    useEffect(() => {
        props.scrollAnimate("aboutus-image");
        props.scrollAnimate("aboutus-block-container");
    }, []);
    return (
        // <div id="aboutus" style={{ backgroundColor: "#0F0E17" }}>
        <div id="aboutus" style={{ backgroundColor: "#F7FAFC" }}>
            <div className="aboutus section__padding">
                <div className="aboutus__feature-title">
                    <div />
                    <h1>About us</h1>
                </div>
                <div className="aboutus-content-container">
                    <section className="aboutus-content">
                        <div className="aboutus-image">
                            <img src={AboutusImg} alt="aboutus-Img" />
                        </div>
                        <div className="aboutus-block-container">
                            <h2>Who we are?</h2>
                            <p>
                                Starting in 2022, currently based at Sydney, we have teamed up with 7 members from
                                various areas, all eager to showcase their skills and quintessential designs.
                            </p>
                            <p>
                                We are talented, enthusiatical team, aim to build world leading virtual try-on system.
                                Looking forward you to joining us!
                            </p>

                            <div className="aboutus-block-button-wrapper">
                                <a className="aboutus-block-button" href="/aboutus">
                                    Learn More &nbsp;&nbsp;
                                    <BsFillArrowRightCircleFill size={27} />
                                </a>
                            </div>
                        </div>
                    </section>

                    {/* <div className="aboutus-content">
                        <h3>Who we are?</h3>
                        <p>
                            Starting in 2022, currently based at Sydney, we have teamed up with 6 members from various
                            areas, all eager to showcase their skills and quintessential designs.{" "}
                        </p>
                        <p>
                            We are talented, enthusiatical team, aim to build world leading virtual try-on system.
                            Looking forward you to joining us!
                        </p>
                        <a className="aboutus-content-button" href="/aboutus">
                            Learn More <BsFillArrowRightCircleFill size={27} />
                        </a>
                    </div>
                    <div className="aboutus-image">
                        <img src={AboutusImg} alt="aboutus-Img" />
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Aboutus;
