import React from "react";

import "./aboutuspage.css";
import Profile1 from "../../assets/profile-1.png";
import Profile2 from "../../assets/profile-2.png";
import Profile3 from "../../assets/profile-3.png";
import Profile4 from "../../assets/profile-4.png";
import Profile5 from "../../assets/profile-5.png";
import Profile6 from "../../assets/profile-6.png";
import Profile7 from "../../assets/profile-7.png";
import { Navbar, Footer, Profile } from "../../components";

const Aboutuspage = () => {
    const isHomepage = false;
    const staff = [
        {
            id: 1,
            profile: Profile1,
            title: "Co-Founder",
            name: "Roger Yang",
            link: "https://www.linkedin.com/in/yikai-yang-834271149/",
            personalLink: null,
        },
        {
            id: 2,
            profile: Profile2,
            title: "Co-Founder",
            name: "Rui Tang",
            link: "https://www.linkedin.com/in/rui-tang-9163bb78/",
            personalLink: null,
        },
        {
            id: 3,
            profile: Profile3,
            title: "Research Advisor",
            name: "Ying Jin",
            link: "https://www.linkedin.com/in/shonejin/",
            personalLink: null,
        },
        {
            id: 4,
            profile: Profile4,
            title: "Advisor",
            name: "Jason k. Eshraghian",
            link: "https://www.linkedin.com/in/jason-eshraghian-9a49497a/",
            personalLink: "https://www.jasoneshraghian.com/cv",
        },
        {
            id: 5,
            profile: Profile5,
            title: "Full Stack Developer",
            name: "Alex Zhuge",
            link: "https://www.linkedin.com/in/xianfeng-zhuge-805258172",
            personalLink: "https://www.mengxinalex.com/",
        },
        {
            id: 6,
            profile: Profile6,
            title: "Full Stack Developer",
            name: "Neil Hong",
            link: "https://www.linkedin.com/in/neil-hong-ba89ba1b8/",
            personalLink: "https://63442d52636a6209715cd199--neil-hong-profolio.netlify.app/",
        },
        {
            id: 7,
            profile: Profile7,
            title: "AI Engineer",
            name: "Ricardo Zhou",
            link: "https://www.linkedin.com/in/%E4%B9%8B%E6%A1%A2-%E5%91%A8-53a93b253/",
            personalLink: null,
        },
    ];
    return (
        <div className="aboutusPage" id="aboutusPage">
            <div className="gradient__bg">
                <Navbar HomepageOrNot={isHomepage} />
            </div>

            <div className="aboutusPage-profile-container">
                <h1 className="gradient__text">Meet Our Talent Teams!</h1>
                <div className="aboutPage-profile-content">
                    {staff.map((p) => {
                        return (
                            <Profile
                                id={p.id}
                                profile={p.profile}
                                title={p.title}
                                name={p.name}
                                link={p.link}
                                personalLink={p.personalLink}
                            />
                        );
                    })}
                </div>
            </div>

            <div className="gradient__bg">
                <Footer />
            </div>
        </div>
    );
};

export default Aboutuspage;
