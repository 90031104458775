import React, { useEffect } from "react";
import { MdInput } from "react-icons/md";

import "./whatwedoPage.css";
import { Navbar, Footer } from "../../components";
import Process1 from "../../assets/process-1.png";
import Process2 from "../../assets/process-2.png";
import Process3 from "../../assets/process-3.png";
import Process4 from "../../assets/process-4.png";
import Front from "../../assets/front.png";
import Left from "../../assets/left.png";
import Right from "../../assets/right.png";
import Up from "../../assets/up.png";
import Output from "../../assets/output.svg";
import RGBD1 from "../../assets/RGBDImage-1.png";
import RGBD2 from "../../assets/RGBDImage-2.png";
import { BsFillArrowRightCircleFill } from "react-icons/bs";

const WhatwedoPage = () => {
    const isHomepage = false;
    useEffect(() => {
        var scroll =
            window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.msRequestAnimationFrame ||
            window.oRequestAnimationFrame ||
            // IE Fallback, you can even fallback to onscroll
            function (callback) {
                window.setTimeout(callback, 1000 / 60);
            };
        var elementsToShow = document.querySelectorAll(".show-on-scroll");

        function loop() {
            elementsToShow.forEach(function (element) {
                if (isElementInViewport(element)) {
                    element.classList.add("is-visible");
                } else {
                    element.classList.remove("is-visible");
                }
            });

            scroll(loop);
        }

        // Call the loop for the first time
        loop();

        // Helper function from: http://stackoverflow.com/a/7557433/274826
        function isElementInViewport(el) {
            var rect = el.getBoundingClientRect();
            return (
                (rect.top <= 0 && rect.bottom >= 0) ||
                (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.top <= (window.innerHeight || document.documentElement.clientHeight)) ||
                (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
            );
        }
    }, []);

    return (
        <div className="whatwedoPage">
            <div className="gradient__bg">
                <Navbar HomepageOrNot={isHomepage} />
            </div>
            <div className="whatwedoPage__title">
                <div />
                <h1 className="gradient__text">How It Works?</h1>
            </div>

            <div className="whatwedoPage__content1-container">
                <img className="whatwedoPage__process show-on-scroll" src={Process1} alt="process-1" />
                <h1>3D scan & analysis</h1>
                <h2>
                    The customer applies the 3D scan app to scan the customer's face and body to get the realistic
                    online 3D avatars.
                </h2>
                <img className="whatwedoPage__process show-on-scroll" src={Process2} alt="process-2" />
                <h1>3D virtual try On</h1>
                <h2>Virtual try the clothes or accessories on the online Web3D virtual fitting room.</h2>
                <img className="whatwedoPage__process show-on-scroll" src={Process3} alt="process-3" />
                <h1>VR visualize the result</h1>
                <h2>Use the VR visualize the virtual try on result.</h2>
                <img className="whatwedoPage__process show-on-scroll" src={Process4} alt="process-4" />
                <h1>Pay and place the order</h1>
                <h2>One-click to buy the clothes or accessories.</h2>
                {/* <div>
                    <img className="whatwedoPage__content1-line" src={Line} alt="line" />
                </div>
                <div className="whatwedoPage__content1">
                    <h1>3D scan & analysis</h1>
                    <img className="whatwedoPage__process" src={Process1} alt="process-1" />
                    <h1>3D virtual try On</h1>
                    <img className="whatwedoPage__process" src={Process2} alt="process-2" />
                    <h1>VR visualize the result</h1>
                    <img className="whatwedoPage__process" src={Process3} alt="process-3" />
                    <h1>Pay and place the order</h1>
                    <img className="whatwedoPage__process" src={Process4} alt="process-4" />
                </div> */}
            </div>
            <div className="whatwedoPage__content2-container">
                <div className="whatwedoPage__title">
                    <div />
                    <h1 className="gradient__text">3D Modelling Demo Example</h1>
                </div>
                <div className="whatwedoPage__content2-section1">
                    <div className="whatwedoPage__content2-title">
                        <h2>Input</h2>
                        <MdInput size={27} className="whatwedoPage__content2-icon" />
                        <p>4 Direction RGBD Image</p>
                    </div>
                    <div className="whatwedoPage__content2-img">
                        <div>
                            <img src={Front} alt="front" />
                            <p>Front</p>
                        </div>
                        <div>
                            <img src={Left} alt="left" />
                            <p>Left</p>
                        </div>
                        <div>
                            <img src={Right} alt="right" />
                            <p>Right</p>
                        </div>
                        <div>
                            <img src={Up} alt="up" />
                            <p>Up</p>
                        </div>
                    </div>
                </div>
                <div className="whatwedoPage__content2-section2">
                    <div className="whatwedoPage__content2-title">
                        <h2>Output</h2>
                        <img src={Output} alt="output" className="whatwedoPage__content2-section2-icon" />
                        <p>High-Fidelity 3D Digital Human Head</p>
                    </div>
                    <div className="whatwedoPage__content2-img">
                        <div>
                            <img src={RGBD1} alt="RGBD1" />
                        </div>
                        <div>
                            <img src={RGBD2} alt="RGBD2" />
                        </div>
                    </div>
                    <div className="whatwedoPage_content2-button-container">
                        <a className="whatwedoPage__content2-button" href="/demo">
                            Try 3D Demo <BsFillArrowRightCircleFill size={27} />
                        </a>
                    </div>
                </div>
            </div>

            <div className="gradient__bg">
                <Footer />
            </div>
        </div>
    );
};

export default WhatwedoPage;
