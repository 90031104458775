import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { Environment, Html, OrbitControls, PerspectiveCamera, Text, TransformControls } from "@react-three/drei";
import { useControls } from "leva";
import create from "zustand";
import { Demo, Footer, Glasses, Hair, HeadDemo, Navbar } from "../../../components";
import Spinner from "../../../components/common/Spinner";

const useStore = create((set) => ({ target: null, setTarget: (target) => set({ target }) }));

function Loader() {
    return (
        <Html center>
            <Spinner />
        </Html>
    );
}

const DiniWigsPage = () => {
    const isHomepage = false;
    const { modes, glasses, hairStyles, eyes, background } = useControls({
        modes: { value: "translate", options: ["translate", "rotate", "scale"] },
        glasses: {
            value: "none",
            options: [
                "Glasses1",
                "Glasses2",
                "Glasses3",
                "Glasses4",
                "Glasses5",
                "Glasses6",
                "Glasses7",
                "Glasses8",
                "Glasses9",
                "Glasses10",
            ],
        },
        hairStyles: {
            value: "none",
            options: [
                "menHairStyle1",
                "menHairStyle2",
                "menHairStyle3",
                "menHairStyle4",
                "menHairStyle5",
                "femaleHairStyle1",
                "femaleHairStyle2",
                "femaleHairStyle3",
                "femaleHairStyle4",
                "femaleHairStyle5",
            ],
        },
        eyes: {
            value: "brownEyes",
            options: ["brownEyes", "blueEyes", "darkBrownEyes", "greyEyes", "greenEyes", "hazelEyes"],
        },
        background: {
            value: "lobby",
            options: ["sunset", "forest", "city", "lobby", "apartment", "dawn", "studio", "park", "night", "warehouse"],
        },
    });

    const { target, setTarget } = useStore();

    return (
        <div className="demoPage">
            <div className="gradient__bg">
                <Navbar HomepageOrNot={isHomepage} />
            </div>
            <div className="demoPage_demo-container" style={{ height: window.innerHeight }}>
                <Canvas camera={{ position: [0, 10, 50] }} onPointerMissed={() => setTarget(null)}>
                    <PerspectiveCamera position={[0.1, 0.1, -0.2]}>
                        <ambientLight args={[0x222222]} intensity={1} />
                        <directionalLight color="0xffffff" position={[1, 0.75, 1]} intensity={1} />
                        <directionalLight color="0xffffff" position={[-1, 0.75, -0.5]} intensity={1} />
                        <directionalLight color="0xffffff" position={[-0.5, 0.5, -0.5]} intensity={1} />
                        <directionalLight color="0x666666" position={[0, -0.5, 1]} intensity={1} />
                        <Suspense fallback={<Loader />}>
                            {/* <Eyes /> */}
                            <HeadDemo name="Demo" modes={modes} useStore={useStore} eyeSelection={eyes} />
                            {glasses !== "none" ? (
                                <Glasses name="Glasses" modes={modes} useStore={useStore} glassesSelection={glasses} />
                            ) : null}
                            {hairStyles !== "none" ? (
                                <Hair name="Hair1" modes={modes} useStore={useStore} hairSelection={hairStyles} />
                            ) : null}
                            <Environment preset={background} background />
                            {target && <TransformControls object={target} mode={modes} />}
                        </Suspense>
                        <OrbitControls makeDefault />
                    </PerspectiveCamera>
                </Canvas>
            </div>

            <div className="gradient__bg demoPage_footer">
                <Footer />
            </div>
        </div>
    );
};

export default DiniWigsPage;
