import React, { useRef, useState } from "react";
import { useLoader } from "@react-three/fiber";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { TextureLoader } from "three";
import { useCursor } from "@react-three/drei";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";

const HeadDemo = (props) => {
    const group = useRef();
    const { useStore, name, eyeSelection } = props;
    const normalScale = new THREE.Vector2(0.5, 0.5);
    const mapTexture = useLoader(
        TextureLoader,
        "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/demo/albedo.png"
    );
    mapTexture.flipY = false;
    const normalTexture = useLoader(
        TextureLoader,
        "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/demo/normal.png"
    );
    normalTexture.flipY = false;
    normalTexture.encoding = THREE.sRGBEncoding;

    const material = new THREE.MeshPhongMaterial({
        // color: "rgb(154,122,107)",
        specular: 0x111111,
        shininess: 20,
        normalScale: normalScale,
        map: mapTexture,
        normalMap: normalTexture,
    });
    const obj = useLoader(
        GLTFLoader,
        "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/demo/head.glb"
    );
    const eyeObj = useLoader(
        FBXLoader,
        "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/demoeyes/Realtime_Eyes.fbx"
    );
    let eyeAlbedo;
    switch (eyeSelection) {
        case "blueEyes":
            eyeAlbedo =
                "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/demoeyes/blueEye_light_albedo_1k.png";
            break;
        case "brownEyes":
            eyeAlbedo =
                "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/demoeyes/brownEye_light_albedo_1k.png";
            break;
        case "darkBrownEyes":
            eyeAlbedo =
                "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/demoeyes/darkBrownEye_light_albedo_1k.png";
            break;
        case "greenEyes":
            eyeAlbedo =
                "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/demoeyes/greenEye_light_albedo_1k.png";
            break;
        case "greyEyes":
            eyeAlbedo =
                "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/demoeyes/greyEye_light_albedo_1k.png";
            break;
        case "hazelEyes":
            eyeAlbedo =
                "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/demoeyes/hazelEye_light_albedo_1k.png";
            break;
        default:
            eyeAlbedo =
                "https://demo-output-i1.s3.ap-southeast-2.amazonaws.com/public/models/demoeyes/brownEye_light_albedo_1k.png";
    }
    const eyeMapTexture = useLoader(TextureLoader, eyeAlbedo);
    const eyeMaterial = new THREE.MeshLambertMaterial({
        map: eyeMapTexture,
    });

    const setTarget = useStore((state) => state.setTarget);
    const [hovered, setHovered] = useState(false);
    useCursor(hovered);
    return (
        <group
            ref={group}
            onClick={(e) => (setTarget(group.current), console.log(e))}
            onPointerOver={() => setHovered(true)}
            onPointerOut={(e) => setHovered(false)}
            // position={[0, 5, 0]}
        >
            <mesh
                geometry={obj.scene.children[0].geometry}
                material={material}
                rotation={[1.5, 0, 0]}
                scale={1}
                position={[0, -0.25, 0.34]}
            >
                <meshPhongMaterial
                    map={mapTexture}
                    normalScale={normalScale}
                    normalMap={normalTexture}
                    specular={0x111111}
                    shininess={20}
                />
            </mesh>
            {/* {eyeSelection === "eyes1" ? ( */}
            <group>
                <mesh geometry={eyeObj.children[0].geometry} material={eyeMaterial} position={[-3.1, 3.55, 3.55]}>
                    <meshLambertMaterial map={eyeMapTexture} />
                </mesh>
                <mesh geometry={eyeObj.children[0].geometry} material={eyeMaterial} position={[3.85, 3.58, 3.3]}>
                    <meshLambertMaterial map={eyeMapTexture} />
                </mesh>
                {/* <mesh
                        geometry={eyeObj.children[0].geometry}
                        material={eyeMaterial}
                        scale={0.015}
                        rotation={[Math.PI / 1, 11, 0]}
                        position={[-3.2, 5.5, 3.4]}
                    ></mesh>
                    <mesh
                        geometry={eyeObj.children[0].geometry}
                        material={eyeMaterial}
                        scale={0.015}
                        rotation={[Math.PI / 1, 11, 0]}
                        position={[3.75, 5.5, 3.2]}
                    ></mesh> */}
            </group>
            {/* ) : null} */}
        </group>
    );
};

// const DemoV2 = () => {
//     return (
//         <div style={{ height: "800px" }}>
//             <Canvas camera={{ position: [0, 10, 50] }}>
//                 <PerspectiveCamera position={[0.1, 0.1, -0.2]}>
//                     <ambientLight args={[0x222222]} intensity={1} />
//                     <directionalLight color="0xffffff" position={[1, 0.75, 0.5]} intensity={1} />
//                     <directionalLight color="0xffffff" position={[-1, 0.75, -0.5]} intensity={2} />
//                     <directionalLight color="0xffffff" position={[-0.5, 0.5, -0.5]} intensity={2} />
//                     <directionalLight color="0x666666" position={[0, -0.5, 1]} intensity={1} />
//                     <Suspense fallback={null}>
//                         <Model />
//                         <Environment preset="studio" background />
//                     </Suspense>
//                     <OrbitControls makeDefault />
//                 </PerspectiveCamera>
//             </Canvas>
//         </div>
//     );
// };

export default HeadDemo;
