import React from "react";
import "./spinner.scss";

export const Spinner = () => {
    return (
        <div className="spinner">
            <div class="cube">
                <div class="sides">
                    <div class="top"></div>
                    <div class="right"></div>
                    <div class="bottom"></div>
                    <div class="left"></div>
                    <div class="front"></div>
                    <div class="back"></div>
                </div>
            </div>
            <div class="text">Loading</div>
        </div>
    );
};

export default Spinner;
